export default [
  {
    key: "nameEn",
    label: "field.nameEn",
    rules: "required|max:200",
    type: "text",
  },
  {
    key: "nameKm",
    label: "field.nameKm",
    rules: "max:200",
    type: "text",
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
