<template>
  <div>
    <b-overlay
      :show="loading"
      :style="{
        width: fullWidth ? '' : `${pw}px`,
        height: fullWidth ? '' : `${ph}px`,
      }"
    >
      <div
        class="image-uploader flex-center-vertically text-center"
        style="min-height: 150px; height: auto; border: 1px dashed"
        :style="{
          borderColor: activeColor,
          cursor: readonly ? '' : 'pointer',
          backgroundColor: readonly ? secondary : '',
          width: fullWidth ? '' : `${pw}px`,
          height: fullWidth ? '' : `${ph}px`,
        }"
        ref="dragDropBox"
        @click="chooseImage"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <div v-if="(!uploadImage && !image) || !value">
          <feather-icon
            icon="ImageIcon"
            size="30"
            :style="{
              color: readonly ? '#ffffff' : activeColor,
            }"
          />
          <div
            class="mt-2"
            :style="{
              color: readonly ? '#ffffff' : activeColor,
            }"
          >
            {{ $t("general.clickOrDragDropToUpload") }}
          </div>
        </div>
        <div v-else>
          <img
            class="img-fluid"
            :class="{
              'p-1': !readonly,
            }"
            :src="uploadImage ? uploadImage : image"
            alt=""
            :style="{
              height: fullWidth ? '' : `${ph}px`,
            }"
          />
        </div>
      </div>
      <input
        type="file"
        style="display: none"
        multiple
        name="fields[assetsFieldHandle][]"
        id="assetsFieldHandle"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        @change="onChange"
        ref="fileInput"
        accept=".jpg,.jpeg,.png"
      />
    </b-overlay>
    <b-button
      class="mt-1"
      variant="danger"
      size="sm"
      @click="removeImage"
      :style="{
        width: fullWidth ? '' : `${pw}px`,
      }"
      v-if="(!readonly && isOptional && value) || showDeleteButton"
    >
      {{ $t("button.delete") }}
    </b-button>
    <br v-if="(!readonly && isOptional && value) || showDeleteButton" />
    <b-button
      class="mt-1"
      variant="info"
      size="sm"
      @click="viewHistory"
      v-show="showUploadHistory"
      :style="{
        width: fullWidth ? '' : `${pw}px`,
      }"
      v-if="!readonly"
    >
      {{ $t("button.viewUploadHistory") }}
    </b-button>

    <b-modal
      :id="`upload-history-${uuid}`"
      centered
      hide-footer
      :title="$t('field.image')"
      size="xl"
      @hide="onHide()"
      v-if="!readonly"
    >
      <b-row class="p-2">
        <b-col>
          <label>{{ $t("general.entries") }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>
        <b-col lg="6" align-self="end">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              :placeholder="$t('general.search')"
              v-model="searchText"
            />

            <b-input-group-prepend>
              <b-button
                variant="primary"
                @click="search"
                v-shortkey="['ctrl', 'enter']"
                @shortkey="search"
                class="mr-2"
              >
                {{ $t("general.search") }}
              </b-button>
              <b-button
                variant="outline-secondary"
                @click="reset"
                v-shortkey="['ctrl', 'esc']"
                @shortkey="reset"
              >
                {{ $t("general.reset") }}
              </b-button>
            </b-input-group-prepend>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row class="p-2">
        <b-col
          sm="6"
          md="3"
          lg="2"
          v-for="imageHistory in imageHistories"
          :key="`imageHistory-${imageHistory.id}`"
        >
          <div class="cursor-pointer" @click="selectImage(imageHistory)">
            <img
              class="img-fluid w-100"
              :src="imageHistory.fileUrl"
              :alt="imageHistory.fileName"
            />
            <p class="pt-2">{{ imageHistory.fileName }}</p>
          </div>
        </b-col>
      </b-row>
      <n-pagination
        class="mt-2"
        :total="total"
        :per-page="$store.state.pagination.perPage"
        :page="page"
        @change="listFile"
      ></n-pagination>
    </b-modal>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import Repository from "@/repositories/RepositoryFactory";
import {
  BOverlay,
  BModal,
  BCard,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import NPagination from "@/components/NPagination";
import vSelect from "vue-select";
import Compressor from "compressorjs";

const FileRepository = Repository.get("file");

export default {
  delimiters: ["${", "}"],

  components: {
    BOverlay,
    BModal,
    BCard,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    vSelect,
    NPagination,
  },

  data: function () {
    return {
      loading: false,
      filelist: [],
      uploadImage: null,
      imageHistories: [],
      searchText: "",
      page: 1,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
    };
  },

  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },

  props: {
    image: {},
    value: {},
    size: {},
    pw: {},
    ph: {},
    path: {
      type: String,
      default: "image",
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Image",
    },
    readonly: {},
    uuid: {
      default: "",
    },
    compressQuality: {
      default: 0.6,
    },
    compressMineType: {
      default: "image/jpeg",
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    showUploadHistory: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    perPage() {
      this.listFile();
    },
  },

  methods: {
    onHide() {},
    search() {
      this.listFile();
    },
    reset() {
      this.searchText = "";
      this.listFile();
    },
    viewHistory() {
      this.$bvModal.show(`upload-history-${this.uuid}`);
      this.listFile();
    },
    listFile(page = 1) {
      FileRepository.index({
        filePath: this.path,
        search: this.searchText,
        fileType: "image",
        page: page,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.imageHistories = [...data.list];
            this.total = data.total;
          }
        })
        .catch()
        .then(() => {});
    },
    selectImage(image) {
      this.$emit("input", image.id);
      this.$emit("change", image);
      this.uploadImage = image.fileUrl;
      this.$bvModal.hide(`upload-history-${this.uuid}`);
    },
    onChange() {
      if (this.readonly) {
        return;
      }

      this.filelist = [...this.$refs.fileInput.files];
      let self = this;
      if (this.filelist[0]) {
        new Compressor(this.filelist[0], {
          quality: this.compressQuality,
          mimeType: this.compressMineType,
          success(result) {
            var reader = new FileReader();
            reader.onload = (e) => {
              const file = {
                filePath: self.path,
                file: e.target.result,
                fileName: self.filelist[0].name,
                fileSize: self.filelist[0].size,
                fileType: "image",
              };
              self.upload(file);
            };
            reader.readAsDataURL(result);
          },
        });
      }
    },
    upload(file) {
      this.loading = true;
      FileRepository.upload(file)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.$emit("input", data.id);
            this.$emit("change", data);
            this.uploadImage = data.fileUrl;
          }
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
    removeImage() {
      this.$emit("delete", null);
      this.$emit("input", null);
      this.$emit("change", null);
      this.uploadImage = null;
    },
    remove() {
      this.filelist.splice(0, 1);
      this.$emit("input", null);
      this.$emit("change", null);
    },
    dragover(event) {
      if (this.readonly) {
        return;
      }

      event.preventDefault();
      this.activeColor = this.primary;
    },
    dragleave(event) {
      if (this.readonly) {
        return;
      }
      this.activeColor = this.secondary;
    },
    drop(event) {
      if (this.readonly) {
        return;
      }
      event.preventDefault();
      this.$refs.fileInput.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },

    chooseImage() {
      if (this.readonly) {
        return;
      }
      this.$emit("blur", null);
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
  },
  setup() {
    // App Name
    const { primary, secondary } = $themeConfig.color;

    let activeColor = secondary;

    return {
      primary,
      secondary,
      activeColor,
    };
  },
};
</script>

<style scoped>
.flex-center-vertically {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
